import {
  ComponentFactoryResolver,
  Injectable,
  Inject
} from '@angular/core'
import { Component } from '@angular/compiler/src/core';

@Injectable()
export class ComponentInjectorService {
  factoryResolver: any;
  rootViewContainer: any;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    console.log('%c viewContainerRef: ', 'background: #fae552; color: #323232;', viewContainerRef);
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(DynamicComponent: Component, timeout?) {
    const factory = this.factoryResolver.resolveComponentFactory(
      DynamicComponent
    );

    const component = factory.create(this.rootViewContainer.parentInjector);
    this.rootViewContainer.insert(component.hostView);

    if (timeout && typeof timeout === 'number' && timeout >= 0) {
      setTimeout(function () {
        component.destroy();
      }, timeout);
    }

    if (timeout && typeof timeout === 'boolean') {
      setTimeout(function () {
        component.destroy();
      }, 3500);
    }
  }
  
}