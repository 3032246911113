import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LotService {

    BASE_URL = environment.baseApiUrl;
    LOTS_URL = '/lots';
    LOTS_SUMMARY_URL = '/lotsummary'
    TRANSFER_URL = '/transfer';
    TRANSFER_SPLIT_URL = '/transfersplit';
    ABSORBED_URL = '/absorbed';

  constructor(private http: HttpClient) { }

  getLot(id, type?): Observable<any> {
    return this.http.get<any>(!type ? this.BASE_URL + this.LOTS_URL + '/' + id : this.BASE_URL + this.LOTS_URL + '/' + id  + '/' + type);
  }

  getLotsByNodeId(node_id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/nodes/' + node_id + this.LOTS_URL);
  }

  getLotTokens(type, id): Observable<any> {
    return this.http.get<any>(this.BASE_URL + '/lots/tokendashboard/' + type + '/' + id);
  }

  getLotSummary(id, type): Observable<any> {
    return this.http.get<any>(this.BASE_URL + this.LOTS_SUMMARY_URL + '/' + id + '/' + type.toLowerCase() );
  }

  transferLots(type, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.TRANSFER_URL + '/' + type, payload);
  }

  transferSplitLots(type, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.TRANSFER_SPLIT_URL + '/' + type, payload);
  }

  editLot(id, lotType, payload): Observable<any> {
    return this.http.put<any>(this.BASE_URL + this.LOTS_URL + '/' + id + '/' + lotType, payload);
  }

  createLot(lotType, payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.LOTS_URL + '/' + lotType, payload);
  }

  createAbsorbedFromLots(payload): Observable<any> {
    return this.http.post<any>(this.BASE_URL + this.LOTS_URL + this.ABSORBED_URL, payload);
  }

}